module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Iceberg Insights - Limited Beta Preview","short_name":"Iceberg","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/img/iceberg_icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4384c3a8848446a77c20fe92c01e4909"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1138796846514054"},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"mainColor":"pampas","secondaryColor":"ruby","buttonColor":"shadyLady","background":"#eeeeee","text":"#222111"},"dark":{"mainColor":"topaz","secondaryColor":"ruby","background":"#222111","text":"#eeeeee"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
